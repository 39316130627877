import React from 'react'

import LabelAndTextInput from '../inputs/LabelAndTextInput'
import Title from '../title/Title'
import Pictogram from '@components/form/pictogram/Pictogram'
import cash from '@assets/form/euro.svg'
import loan from '@assets/form/loan.svg'
import {
	setContributionAmount,
	setInterestRate,
	setLoanAmount,
	setLoanDuration,
} from '@components/form/sections/fundingSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import type { RootState } from '@/store'
import { requiredValidator, numberValidator, decimalValidator } from '@/utils/validators'

import './Section.css'

interface FundingProps {
	fundingType: string
	onSetFundingType: (value: string) => void
}

function Funding(props: FundingProps) {
	return (
		<div className='section'>
			<Title>Votre financement</Title>
			<div className='pictograms'>
				<Pictogram
					className={'picto1' + (props.fundingType === 'loan' ? ' active' : '')}
					src={loan}
					id='loan'
					onChange={() => props.onSetFundingType('loan')}
				>
					Crédit immobilier
				</Pictogram>
				<Pictogram
					className={'picto1' + (props.fundingType === 'cash' ? ' active' : '')}
					src={cash}
					id='cash'
					onChange={() => props.onSetFundingType('cash')}
				>
					Comptant
				</Pictogram>
			</div>
			{props.fundingType === 'loan' && <Loan />}
			{props.fundingType === 'cash' && <Cash />}
		</div>
	)
}

function Loan() {
	const dispatch = useAppDispatch()

	const getContributionAmount = useAppSelector(
		(state: RootState) => state.funding.contributionAmount,
	)
	const onSetContributionAmount = (value: string) => {
		dispatch(setContributionAmount(value))
	}

	const getLoanAmount = useAppSelector((state: RootState) => state.funding.loanAmount)
	const onSetLoanAmount = (value: string) => {
		dispatch(setLoanAmount(value))
	}

	const getLoanDuration = useAppSelector((state: RootState) => state.funding.loanDuration)
	const onSetLoanDuration = (value: string) => {
		dispatch(setLoanDuration(value))
	}

	const getInterestRate = useAppSelector((state: RootState) => state.funding.interestRate)
	const onSetInterestRate = (value: string) => {
		dispatch(setInterestRate(value))
	}

	return (
		<div>
			<div className='formContent'>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='contributionAmount'
						label="Montant de l'apport"
						name='contributionAmount'
						value={getContributionAmount}
						placeholder='Ex : 50 000 €'
						onChange={onSetContributionAmount}
						validator={(input: string) => [requiredValidator(input), numberValidator(input)]}
						autocomplete='number'
					/>
					<LabelAndTextInput
						id='loanAmount'
						label='Montant du prêt'
						name='loanAmount'
						placeholder='Ex : 450 000 €'
						value={getLoanAmount}
						onChange={onSetLoanAmount}
						validator={(input: string) => [requiredValidator(input), numberValidator(input)]}
						autocomplete='number'
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='loanDuration'
						label='Durée du prêt'
						name='loanDuration'
						placeholder='Ex : 20 ans'
						value={getLoanDuration}
						onChange={onSetLoanDuration}
						validator={(input: string) => [requiredValidator(input), numberValidator(input)]}
						autocomplete='number'
					/>
					<LabelAndTextInput
						id='interestRate'
						type='decimal'
						label='Taux d’intérêt'
						name='interestRate'
						placeholder='Ex : 1,5'
						value={getInterestRate}
						onChange={onSetInterestRate}
						validator={(input: string) => [requiredValidator(input), decimalValidator(input)]}
						autocomplete='number'
					/>
				</div>
			</div>
		</div>
	)
}

function Cash() {
	return <div></div>
}

export default Funding
