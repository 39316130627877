import { createSlice } from '@reduxjs/toolkit'

interface IPerson {
	gender: string
	lastName: string
	firstName: string
	phone: string
	email: string
	birthDate: string
	birthCity: string
	birthCountry: string
	nationality: string
	profession: string
	address: string
	married: string
	children: string
	marriageCity: string
	marriageDate: string
	hasContract: string
	contractDate: string
	contractPlace: string
	contractType: string
}

const newPerson: IPerson = {
	gender: '',
	lastName: '',
	firstName: '',
	phone: '',
	email: '',
	birthDate: '',
	birthCity: '',
	birthCountry: '',
	nationality: '',
	profession: '',
	address: '',
	married: '',
	children: '',
	marriageCity: '',
	marriageDate: '',
	hasContract: '',
	contractDate: '',
	contractPlace: '',
	contractType: '',
}

type PersonState = {
	persons: IPerson[]
}

const initialState: PersonState = {
	persons: [],
}

export const personSlice = createSlice({
	name: 'Person',
	initialState,
	reducers: {
		addPerson: (state, action) => {
			if (state.persons[action.payload]) {
				return
			}
			state.persons.push(newPerson)
		},
		removePerson: (state, action) => {
			state.persons = state.persons.filter((_, index) => index !== action.payload)
		},
		setGender: (state, action) => {
			state.persons[action.payload.index].gender = action.payload.value
		},
		setLastName: (state, action) => {
			state.persons[action.payload.index].lastName = action.payload.value
		},
		setFirstName: (state, action) => {
			state.persons[action.payload.index].firstName = action.payload.value
		},
		setPhone: (state, action) => {
			state.persons[action.payload.index].phone = action.payload.value
		},
		setEmail: (state, action) => {
			state.persons[action.payload.index].email = action.payload.value
		},
		setBirthDate: (state, action) => {
			state.persons[action.payload.index].birthDate = action.payload.value
		},
		setBirthCity: (state, action) => {
			state.persons[action.payload.index].birthCity = action.payload.value
		},
		setBirthCountry: (state, action) => {
			state.persons[action.payload.index].birthCountry = action.payload.value
		},
		setNationality: (state, action) => {
			state.persons[action.payload.index].nationality = action.payload.value
		},
		setProfession: (state, action) => {
			state.persons[action.payload.index].profession = action.payload.value
		},
		setAddress: (state, action) => {
			state.persons[action.payload.index].address = action.payload.value
		},
		setMarried: (state, action) => {
			state.persons[action.payload.index].married = action.payload.value
		},
		setChildren: (state, action) => {
			state.persons[action.payload.index].children = action.payload.value
		},
		setMarriageCity: (state, action) => {
			state.persons[action.payload.index].marriageCity = action.payload.value
		},
		setMarriageDate: (state, action) => {
			state.persons[action.payload.index].marriageDate = action.payload.value
		},
		setHasContract: (state, action) => {
			state.persons[action.payload.index].hasContract = action.payload.value
		},
		setContractDate: (state, action) => {
			state.persons[action.payload.index].contractDate = action.payload.value
		},
		setContractPlace: (state, action) => {
			state.persons[action.payload.index].contractPlace = action.payload.value
		},
		setContractType: (state, action) => {
			state.persons[action.payload.index].contractType = action.payload.value
		},
	},
})

export const {
	addPerson,
	removePerson,
	setGender,
	setLastName,
	setFirstName,
	setPhone,
	setEmail,
	setBirthDate,
	setBirthCity,
	setBirthCountry,
	setNationality,
	setProfession,
	setAddress,
	setMarried,
	setChildren,
	setMarriageCity,
	setMarriageDate,
	setHasContract,
	setContractDate,
	setContractPlace,
	setContractType,
} = personSlice.actions

export default personSlice.reducer
