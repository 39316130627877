import React from 'react'
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom'

import Root from './Root'
import Form from './components/form/Form'
import { ValidatorProvider } from '@/utils/validatorProvider'

import './App.css'

const router = createBrowserRouter(
	createRoutesFromElements([
		<Route path='/' element={<Root />} />,
		<Route path='/form' element={<Form />} />,
	]),
)

function App() {
	return (
		<ValidatorProvider>
			<RouterProvider router={router} />
		</ValidatorProvider>
	)
}

export default App
