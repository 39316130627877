import React from 'react'

import './Button.css'

interface ButtonProps {
	type?: 'button' | 'submit' | 'reset'
	children: React.ReactNode
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

function Button(props: ButtonProps) {
	return (
		<button type={props.type ?? 'button'} onClick={props.onClick}>
			{props.children}
		</button>
	)
}

export default Button
